import surveyJson from "../config/survey.json";
import cdcJson from "../config/cdc.json";
import emailNotificationJson from "../config/emailNotification.json";
import surveyResult from "../config/surveyResult.json";
import ApiSurveysService from "@/services/api/modules/surveysService";

export default {
  async getSurveys({ commit, dispatch }, page = {}) {
    commit("SET_SURVEY", []);

    const { status, data } = await ApiSurveysService.list({ ...page });
    const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(page).length === 0 ? data.data : data;

      commit("SET_SURVEY", responseData);
    }
  },

  async save({ commit, dispatch }, payload) {
    payload.questions_data = JSON.parse(payload.questions_data);

    var { status, data } = await ApiSurveysService.create(payload);

    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;
    dispatch(
      "snackbar/show",
      {
        text: message,
      },
      {
        root: true,
      }
    );
    if (status == 200) {
      commit("SET_SURVEY", data.data[0]);
    }
  },

  async deleteSurveys({ commit, dispatch }, ids) {
    let payload = { ids };

    const { status, data } = await ApiSurveysService.deleteSurveys(payload);
    if (status !== 200) {
      const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {

    }
  },

  async updateSurveys({ commit, dispatch }, payload) {
    const { status, data } = await ApiSurveysService.updateSurveys(payload);

    if (status !== 200) {
      const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {

    }
  },

  async find({ commit, dispatch }, id) {
    commit("SET_SURVEY", null);
    const { status, data } = await ApiSurveysService.find(id);
    const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_SURVEY", data.data);
    }
  },

  async update({ commit, dispatch }, payload) {
    payload.questions_data = JSON.parse(payload.questions_data);

    var { status, data } = await ApiSurveysService.update(payload, payload.id);

    const message = data.errors ? Object.values(data.errors).join(" ") : data.message;
    dispatch(
      "snackbar/show",
      {
        text: message,
      },
      {
        root: true,
      }
    );
    if (status == 200) {
      commit("SET_SURVEY", data.data[0]);
    }
  },

  async downloadTextBlastLogs({ commit, dispatch }, page = {}) {
    const { status, data } = await ApiSurveysService.downloadTextBlastLogs(
      page.id,
      page.q,
      page.date
    );

    const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(page).length === 0 ? data.data : data;

      return responseData;
    }
  },

  async getTextBlastLogs({ commit, dispatch }, page = {}) {
    commit("SET_TEXTBLAST_LOGS", []);
    const { status, data } = await ApiSurveysService.getTextBlastLogs({ ...page });

    const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(page).length === 0 ? data.data : data;

      commit("SET_TEXTBLAST_LOGS", responseData);
    }
  },

  async getResultsPerSurvey({ commit, dispatch }, page = {}) {
    commit("SET_SURVEY_RESULTS", []);

    const { status, data } = await ApiSurveysService.getResultsBySurveyId(
      page.page,
      page.per_page,
      page.q,
      page.survey_id,
      page.date,
      page.availed_service_date,
      page.regions,
      page.zones,
      page.groups,
      page.dealers
    );
    const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(page).length === 0 ? data.data : data;

      commit("SET_SURVEY_RESULTS", responseData);
    }
  },

  async getCdcsPerSurvey({ commit, dispatch }, payload = {}) {
    commit("SET_SURVEY_CDCS", []);

    const {
      page,
      per_page,
      q,
      survey_id,
      date,
      regions,
      zones,
      groups,
      dealers,
      order_by,
      sort
    } = payload

    const { status, data } = await ApiSurveysService.getCdcsPerSurvey({
      page,
      per_page,
      q,
      survey_id,
      date,
      regions,
      zones,
      groups,
      dealers,
      order_by,
      sort
    });
    const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(payload).length === 0 ? data.data : data;

      commit("SET_SURVEY_CDCS", responseData);
    }
  },

  async getCSCdcs({ commit, dispatch }, payload = {}) {
    commit("SET_SURVEY_CDCS", []);

    const { page, per_page, q, date, regions, zones, groups, dealers, order_by, sort } = payload;

    const { status, data } = await ApiSurveysService.getCSCdcs({
      page,
      per_page,
      q,
      date,
      regions,
      zones,
      groups,
      dealers,
      order_by,
      sort,
    });
    const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(payload).length === 0 ? data.data : data;

      commit("SET_SURVEY_CDCS", responseData);
    }
  },

  async getDSSICdcs({ commit, dispatch }, payload = {}) {
    commit("SET_SURVEY_CDCS", []);

    const { page, per_page, q, date, regions, zones, groups, dealers, order_by, sort } = payload;

    const { status, data } = await ApiSurveysService.getDSSICdcs({
      page,
      per_page,
      q,
      date,
      regions,
      zones,
      groups,
      dealers,
      order_by,
      sort,
    });
    const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(payload).length === 0 ? data.data : data;

      commit("SET_SURVEY_CDCS", responseData);
    }
  },

  async downloadEmailNotificationReport({ commit, dispatch }, page = {}) {
    // commit("SET_EMAIL_NOTIFICATION", []);
    const { status, data } = await ApiSurveysService.downloadEmailNotificationReport(page.q, page.date);

    const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(page).length === 0 ? data.data : data;

      return responseData;
      // commit("SET_EMAIL_NOTIFICATION", responseData);
    }
  },

  async getEmailNotifications({ commit, dispatch }, page = {}) {
    commit("SET_EMAIL_NOTIFICATION", []);

    const { status, data } = await ApiSurveysService.getEmailNotifications({
      page: page.page,
      per_page: page.itemsPerPage,
      q: page.q,
      date: page.dates,
      sort: (page.sortBy) ? page.sortBy.sortBy : '',
      order_by: (page.sortBy) ? page.sortBy.orderBy : '',
      dealers: page.dealers,
    });

    const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      let responseData = Object.keys(page).length === 0 ? data.data : data;

      commit("SET_EMAIL_NOTIFICATION", responseData);
    }
  },

  async getSurveyResult({ commit, dispatch }, page = {}) {
    commit("SET_EMAIL_NOTIFICATION", surveyResult.result);
  },

  async findSurveyResult({ commit, dispatch }, id) {
    commit("SET_SURVEY_RESULT", null);
    const { status, data } = await ApiSurveysService.findResult(id);
    const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_SURVEY_RESULT", data.data);
    }
  },

  async findSurveyCdc({ commit, dispatch }, id) {
    commit("SET_SURVEY_CDC", null);
    const { status, data } = await ApiSurveysService.findCdc(id);
    const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
          timeout: 10000,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_SURVEY_CDC", data.data);
    }
  },

  async updateSurvey({ commit, dispatch }) {

  },

  async downloadSurveyResult({ commit, dispatch }, param = {}) {
    const { status, data } = await ApiSurveysService.downloadSurveyResult(
      param.q,
      param.survey_id,
      param.date,
      param.availed_service_date,
      param.regions,
      param.zones,
      param.groups,
      param.dealers
    );
    const errorMessage = data.errors
      ? Object.values(data.errors).join(" ")
      : "Failed to download survey results.";
    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      const url = data.data.url;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data.data.filename);
      document.body.appendChild(link);
      link.click();
    }
  },

  async downloadDcsiRaw({ commit, dispatch }, param = {}) {
    const { status, data } = await ApiSurveysService.downloadDcsiRaw(
      param.q,
      param.survey_id,
      param.date,
      param.regions,
      param.zones,
      param.groups,
      param.dealers
    );
    const errorMessage = data.errors
      ? Object.values(data.errors).join(" ")
      : "Failed to download survey results.";
    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      const url = data.data.url;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data.data.filename);
      document.body.appendChild(link);
      link.click();
    }
  },

  async downloadCdc({ commit, dispatch }, param = {}) {
    const { status, data } = await ApiSurveysService.downloadCdc(
      param.q,
      param.survey_id,
      param.date,
      param.regions,
      param.zones,
      param.groups,
      param.dealers
    );
    const errorMessage = data.errors
      ? Object.values(data.errors).join(" ")
      : "Failed to download survey cdcs.";
    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      const url = data.data.url;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data.data.filename);
      document.body.appendChild(link);
      link.click();
    }
  },

  async downloadCSCdc({ commit, dispatch }, param = {}) {
    const { status, data } = await ApiSurveysService.downloadCSCdc(
      param.q,
      param.date,
      param.regions,
      param.zones,
      param.groups,
      param.dealers
    );
    const errorMessage = data.errors
      ? Object.values(data.errors).join(" ")
      : "Failed to download survey cdcs.";
    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      const url = data.data.url;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data.data.filename);
      document.body.appendChild(link);
      link.click();
    }
  },

  async downloadDSSICdc({ commit, dispatch }, param = {}) {
    const { status, data } = await ApiSurveysService.downloadDSSICdc(
      param.q,
      param.date,
      param.regions,
      param.zones,
      param.groups,
      param.dealers
    );
    const errorMessage = data.errors
      ? Object.values(data.errors).join(" ")
      : "Failed to download survey cdcs.";
    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      const url = data.data.url;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data.data.filename);
      document.body.appendChild(link);
      link.click();
    }
  },

  async getSurveyCategories({ commit, dispatch }, payload) {
    commit("SET_SURVEY_CATEGORIES", []);

    const { status, data } = await ApiSurveysService.surveyCategories(payload);
    const errorMessage = data.errors ? Object.values(data.errors).join(" ") : data.message;

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: errorMessage,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_SURVEY_CATEGORIES", data.data);
    }
  },

  async getFactorsByCategory({ commit, dispatch }, category) {
    commit("SET_FACTORS_BY_CATEGORY", []);
    const { status, data } = await ApiSurveysService.getFactorsByCategory(category);

    if (status !== 200) {
      dispatch(
        "snackbar/show",
        {
          text: data.message,
        },
        {
          root: true,
        }
      );
    } else {
      commit("SET_FACTORS_BY_CATEGORY", data.data);
    }
  },
};
